import React, {Component} from 'react';
import Layout from '../layout';
import axios from 'axios';

class ContactPage extends Component {

    formUrl = 'https://script.google.com/macros/s/AKfycbxP9uA0-6LqT7GdVJ6hO-1o49f2Hx0OISyI-BBk85UksQPsFo_p/exec?';

    nombreInput;
    apellidoInput;
    mailInput;
    asuntoInput;
    comentarioInput;

    state = {
        nombreError: false,
        apellidoError: false,
        mailError: false,
        asuntoError: false,
        submited: false,
    }

    submitForm = () => {
        let nombre = this.nombreInput.value;
        let apellido = this.apellidoInput.value;
        let mail = this.mailInput.value;
        let asunto = this.asuntoInput.value;
        let comentario = this.comentarioInput.value;

        let valid = this.validate(nombre, 'nombreError');
        valid = valid && this.validate(apellido, 'apellidoError');
        valid = valid && this.validate(mail, 'mailError');
        valid = valid && this.validate(asunto, 'asuntoError');

        if(valid) {
            let url = this.formUrl;
            url += `nombre=${nombre}`;
            url += `&apellido=${apellido}`;
            url += `&mail=${mail}`;
            url += `&asunto=${asunto}`;
            url += `&comentario=${comentario}`;
            axios.get(url).then(rta => {
                this.setState({submited: true});
                this.nombreInput.vlaue = null;
                this.apellidoInput.vlaue = null;
                this.mailInput.vlaue = null;
                this.asuntoInput.vlaue = 'Consulta';
                this.comentarioInput.vlaue = null;
            })
        }
    }

    validate = (value, name) => {
        let newState = {};
        if(!value) {
            newState[name] = true;
        } else {
            newState[name] = false;
        }
        this.setState(newState);
        return !newState[name];
    }

    render() {
        return(
            <Layout>

                <h1 className={"form-title"}>Contacto</h1>

                <div className={"form-strip"}>

                    <div className={"form-container"}>

                        <label className={"form-label"}>
                            Nombre*<br/>
                            <input type="text" name="nombre" className={"form-input"} ref={ref=>this.nombreInput=ref}/>
                        </label>
                        {this.state.nombreError ? 
                            <div className={"form-error"}>Este campo es obligatorio</div> 
                            :
                            null
                        }
                        <br/>

                        <label className={"form-label"}>Apellido*<br/>
                            <input type="text" name="apellido" className={"form-input"} ref={ref=>this.apellidoInput=ref}/>
                        </label>
                        {this.state.apellidoError ? 
                            <div className={"form-error"}>Este campo es obligatorio</div> 
                            :
                            null
                        }
                        <br/>

                        <label className={"form-label"}>Mail*<br/>
                            <input type="text" name="mail" className={"form-input"} ref={ref=>this.mailInput=ref}/>
                        </label>
                        {this.state.mailError ? 
                            <div className={"form-error"}>Este campo es obligatorio</div> 
                            :
                            null
                        }
                        <br/>

                        <label className={"form-label"}>Asunto*<br/>
                            <select name="asunto" className={"form-input"} ref={ref=>this.asuntoInput=ref}>
                                <option selected={true}>Consulta</option>
                                <option>Mandanos tu propuesta</option>
                                <option>Sugerencias y comentarios</option>
                            </select>
                        </label>
                        {this.state.asuntoError ? 
                            <div className={"form-error"}>Este campo es obligatorio</div> 
                            :
                            null
                        }
                        <br/>

                        

                        <label className={"form-label"}>Comentario<br/>
                            <textarea name="comentario" rows="4" className={"form-input"} ref={ref=>this.comentarioInput=ref}></textarea>
                        </label>
                        <br/>

                        <button className={"form-button"} onClick={this.submitForm}>Enviar comentario</button>

                        {this.state.submited ? 
                            <div className={"form-submited"}>¡Formulario enviado!</div> 
                            :
                            null
                        }

                    </div>

                </div>

            </Layout>
        )
    }

}

export default ContactPage;
